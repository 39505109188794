<template>
<div id="app" data-app>
  <v-card>
  <v-card-title>
      <h2>EXERCISES</h2>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
  </v-card-title>
  <v-data-table
    :items="records"
    :search="search"
    :headers="headers"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-spacer></v-spacer>
        <v-dialog
          fullscreen
          v-model="dialogCommodities"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="m-2"
              v-bind="attrs"
              v-on="on"
            >
              New Commodity Exercise
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span id="commodTitle" class="text-h5 ttt">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="6"
                    sm="3"
                    md="3"
                    v-show="show"
                  >
                  <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                  >
                  <span class="h3 btn-light">SEZIONE PRINCIPALE (Comm.)</span>
                    <v-text-field
                      class="mt-4"
                      v-model="editedItem.saveName"
                      label="TITOLO"
                      :rules="nameRules"
                      required
                      @input="v => { editedItem.saveName = v.toUpperCase()}"
                    ></v-text-field>
                    <v-text-field
                      v-model="editedItem.title"
                      label="TICKER"
                      :rules="nameRules"
                      required
                      @input="v => { editedItem.title = v.toUpperCase()}"
                      @focusout="updateState()"
                    ></v-text-field>
                    <v-select
                      v-model="editedItem.sector"
                      :items="sectors"
                      :rules="nameRules"
                      required
                      label="SETTORE"
                    ></v-select>
                    <v-select
                      v-model="editedItem.buySell"
                      :items="buySell"
                      :rules="nameRules"
                      required
                      @change="updateState()"
                      @focusout="updateStop(editedItem)"
                      label="POSIZIONE"
                    ></v-select>
                    <v-select
                      v-model="editedItem.strategy"
                      :items="strategies"
                      :rules="nameRules"
                      required
                      label="STRATEGIA"
                    ></v-select>
                    <v-select
                      v-model="editedItem.seasonal"
                      :items="seasonalities"
                      label="STAGIONALITA"
                      :rules="nameRules"
                      required
                    ></v-select>
                    <v-select
                      v-model="editedItem.rsi"
                      :items="rsis"
                      :rules="nameRules"
                      required
                      label="RSI"
                    ></v-select>
                    <v-select
                      v-model="editedItem.risk"
                      :items="risks"
                      :rules="nameRules"
                      required
                      label="RISCHIO"
                    ></v-select>
                    <v-select
                      v-model="editedItem.type"
                      :items="types"
                      :rules="nameRules"
                      required
                      label="TIPO"
                    ></v-select>
                    <v-select
                      v-model="editedItem.deadlines"
                      :items="deadlines"
                      :rules="nameRules"
                      required
                      label="SCADENZE"
                    ></v-select>
                    <span class="h3 btn-light">SEZIONE REPLAY</span>
                    <p style="font-size: 10px;margin: 0px;">DATA INIZIO</p>
                    <v-text-field type="date" 
                      :rules="nameRules"
                      required
                      style="border: solid 1px;font-size: 20px;width: 270px;"
                      @focusout="updateState('start_date',editedItem.start_date)"  
                      v-model="editedItem.start_date"></v-text-field>
                    <p style="font-size: 10px;margin: 0px;">DATA FINE</p>
                    <v-text-field type="date" 
                      :rules="nameRules"
                      required
                      style="border: solid 1px;font-size: 20px;width: 270px;margin-bottom: 10px"
                      @focusout="updateState('end_date',editedItem.end_date)"  
                      v-model="editedItem.end_date"></v-text-field>
                    <v-spacer></v-spacer>
                    <v-btn
                      style="float: left;background: yellow;color: black;"
                      @click="close"
                    >BACK</v-btn>
                    <v-btn
                      style="float: right;background: #3699FF;color: white;"
                      @click="validateStep1(editedItem);"
                    >NEXT</v-btn>
                    </v-form>
                  </v-col>
                  <!--  SEZIONE SOGLIE -->
                  <v-col
                    cols="6"
                    sm="3"
                    md="3"
                    v-show="!thresholds"
                  >
                  <v-form
                    ref="form2"
                    v-model="valid"
                    lazy-validation
                  >
                  <span class="h3 btn-light">SEZIONE SOGLIE</span>
                    <v-text-field
                      style="padding-top:20px"
                      v-model="editedItem.margin"
                      @focusout="updateStop(editedItem)"
                      label="STOP LOSS ($)"
                      :rules="nameRules"
                      required
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="editedItem.widthLevel"
                      @focusout="updateStop(editedItem)"
                      label="AMPIEZZA LIVELLO"
                      :rules="nameRules"
                      required
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="editedItem.bronze_price"
                      @focusout="updateStop(editedItem)"
                      label="PREZZO BRONZE"
                      :rules="nameRules"
                      required
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="editedItem.silver_price"
                      @focusout="updateStop(editedItem)"
                      label="PREZZO SILVER"
                      :rules="nameRules"
                      required
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="editedItem.gold_price"
                      @focusout="updateStop(editedItem)"
                      label="PREZZO GOLD"
                      :rules="nameRules"
                      required
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="editedItem.stop_bronze_price"
                      label="STOP PREZZO BRONZE"
                      disabled
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="editedItem.stop_silver_price"
                      label="STOP PREZZO SILVER"
                      disabled
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="editedItem.stop_gold_price"
                      label="STOP PREZZO GOLD"
                      disabled
                    >
                    </v-text-field>
                    <v-spacer></v-spacer>
                    <v-btn
                      style="float: left;background: #3699FF;color: white;"
                      @click="thresholds = !thresholds; show = !show"
                    >
                      PREV
                    </v-btn>
                    <v-btn
                      style="float: right;background: #3699FF;color: white;"
                      @click="validateStep2(editedItem);"
                    >
                      NEXT
                    </v-btn>
                    </v-form>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="3"
                    md="3"
                    v-show="!levels"
                  >
                  <span class="h3 btn-light">SEZIONE BREAKPOINTS</span>
                    <br<br>
                    <span class="h4 btn-outline-primary mt-5">SEZIONE BRONZE</span>
                    <br>
                    <div style="background:#ffe5b6">
                      <v-textarea
                        style="padding-top: 10px !important;"
                        rows="5"
                        v-model="editedItem.message_bronze_break"
                        label="MESSAGGIO"
                      >
                      </v-textarea>
                      <!--v-text-field
                        v-model="editedItem.title_bronze_break"
                        label="TITOLO"
                      >
                      </v-text-field>
                      <v-select
                        v-model="editedItem.type_bronze_break"
                        :items="breakpoint_type"
                        label="TIPO"
                      ></v-select-->
                      <input type="date" 
                      style="border: solid 1px;font-size: 20px;width: 270px;margin-bottom: 10px"
                      @focusout="updateState('bronze_break',editedItem.date_bronze_break)"
                      v-model="editedItem.date_bronze_break">
                    </div>
                    <v-spacer></v-spacer>
                    <span class="h4 btn-outline-primary mt-5">SEZIONE SILVER</span>
                    <br>
                    <div style="background:#F3F6F9">
                      <v-textarea
                        style="padding-top: 10px !important;"
                        rows="5"
                        v-model="editedItem.message_silver_break"
                        label="MESSAGGIO"
                      >
                      </v-textarea>
                      <!--v-text-field
                        v-model="editedItem.title_silver_break"
                        label="TITOLO"
                      >
                      </v-text-field>
                      <v-select
                        v-model="editedItem.type_silver_break"
                        :items="breakpoint_type"
                        label="TIPO"
                      ></v-select-->
                      <input type="date" 
                      style="border: solid 1px;font-size: 20px;width: 270px;margin-bottom: 10px"
                      @focusout="updateState('silver_break',editedItem.date_silver_break)"
                      v-model="editedItem.date_silver_break">
                    </div>
                    <v-spacer></v-spacer>
                    <span class="h4 btn-outline-primary mt-5">SEZIONE GOLD</span>
                    <br>
                    <div style="background:#f3f7029c">
                      <v-textarea
                        style="padding-top: 10px !important;"
                        rows="5"
                        v-model="editedItem.message_gold_break"
                        label="MESSAGGIO"
                      >
                      </v-textarea>
                      <!--v-text-field
                        v-model="editedItem.title_gold_break"
                        label="TITOLO"
                      >
                      </v-text-field>
                      <v-select
                        v-model="editedItem.type_gold_break"
                        :items="breakpoint_type"
                        label="TIPO"
                      ></v-select-->
                      <input type="date" 
                      style="border: solid 1px;font-size: 20px;width: 270px;margin-bottom: 10px"
                      @focusout="updateState('goldbreak',editedItem.date_gold_break)"
                      v-model="editedItem.date_gold_break">
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn
                      style="float: left;background: #3699FF;color: white;"
                      @click="levels = !levels; thresholds = !thresholds"
                    >
                      PREV
                    </v-btn>
                    <v-btn
                      style="float: right;background: #3699FF;color: white;"
                      @click="levels = !levels; traderbot= !traderbot; updateState('bronze_break',editedItem.date_bronze_break);updateState('silver_break',editedItem.date_silver_break);updateState('gold_break',editedItem.date_gold_break)"
                    >
                      NEXT
                    </v-btn>
                  </v-col>
                   <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    v-show="!traderbot"
                  >
                  <v-form
                    ref="form3"
                    v-model="valid"
                    lazy-validation
                  >
                  <span class="h3 btn-light">SEZIONE TRADERBOT</span>
                    <br<br>
                    <br>
                    <div class="row">
                      <div class="col-6">
                        <span class="h3 btn-light">MESSAGGIO INIZIALE</span>
                        <vue-editor id="editor1"
                        v-model="editor1Content" 
                        :editorToolbar="customToolbar" 
                        :rules="nameRules"
                        required
                      ></vue-editor>
                      </div>
                      <div class="col-6">
                        <span class="h3 btn-light">MESSAGGIO FINALE</span>
                        <vue-editor id="editor2" 
                        v-model="editor2Content" 
                        :editorToolbar="customToolbar"
                        :rules="nameRules"
                        required
                        ></vue-editor>
                        <!--v-textarea
                          rows="15"
                          style="border: solid 1px gray;padding-top: 10px !important;"
                          v-model="editedItem.traderbot_message_result"
                          label="MESSAGGIO FINALE"
                        >
                        </v-textarea-->
                      </div>
                    </div>
                    <div style="background:#ffe5b6;display:none">
                      <v-textarea
                        v-model="editedItem.traderbot_message_bronze"
                        label="BRONZE MESSAGE"
                      >
                      </v-textarea>
                    </div>
                    <v-spacer></v-spacer>
                    <br>
                    <div style="background:#F3F6F9;display:none">
                     <v-textarea
                        v-model="editedItem.traderbot_message_silver"
                        label="SILVER MESSAGE"
                      >
                      </v-textarea>
                    </div>
                    <v-spacer></v-spacer>
                    <br>
                    <div style="background:#f3f7029c;display:none">
                      <v-textarea
                        v-model="editedItem.traderbot_message_gold"
                        label="GOLD MESSAGE"
                      >
                      </v-textarea>
                    </div>
                    <v-spacer></v-spacer>
                    <br>
                    <div style="background:#ff49499c;display:none">
                      <v-textarea
                        v-model="editedItem.traderbot_message_not_interested"
                        label="NOT INTERESTED MESSAGE"
                      >
                      </v-textarea>
                    </div>
                    <br><br>
                    <v-btn
                      style="float: left;background: #3699FF;color: white;"
                      @click="levels = !levels; traderbot= !traderbot"
                    >PREV</v-btn>
                    <v-btn v-if="operation!='Updated'" 
                      style="float: right;background: #3699FF;color: white;"
                      @click="validateStep3();"
                    >SAVE</v-btn>
                    <v-btn v-if="operation=='Updated'" 
                      style="float: right;background: #3699FF;color: white;"
                      @click="validateStep3();"
                    >UPDATE</v-btn>
                    </v-form>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="9"
                    md="9"
                    v-show="traderbot"
                  >
                     <basic-chart-output
                      v-bind:windowHeight="'700'"                  
                      v-bind:ref="'playback'"
                      v-bind:namespace="namespace"
                      style="border: 1px solid darkgray;"
                    ></basic-chart-output>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          fullscreen
          v-model="dialogStocks"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="m-2"
              v-bind="attrs"
              v-on="on"
            >
              New Stock Exercise
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span id="stocksTitle" class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="6"
                    sm="3"
                    md="3"
                    v-show="show"
                  >
                  <span class="h3 btn-light">SEZIONE PRINCIPALE  (Stock.)</span>
                    <v-text-field
                      class="mt-4"
                      v-model="editedItem.saveName"
                      label="NAME"
                      @input="v => { editedItem.saveName = v.toUpperCase()}"
                    ></v-text-field>
                    <v-text-field
                      v-model="editedItem.title"
                      label="TICKER"
                      @input="v => { editedItem.title = v.toUpperCase()}"
                      @focusout="updateStockState()"
                    ></v-text-field>
                    <v-select
                      v-model="editedItem.sector"
                      :items="stockSectors"
                      label="SETTORE"
                    ></v-select>
                    <v-select
                      v-model="editedItem.buySell"
                      :items="buySell"
                      @change="updateStockState()"
                      @focusout="updateStop(editedItem)"
                      label="POSIZIONE"
                    ></v-select>
                    <v-select
                      v-model="editedItem.strategy"
                      :items="stockStrategies"
                      label="STRATEGIA"
                    ></v-select>
                    <!--v-select 
                      v-model="editedItem.seasonal"
                      :items="seasonalities"
                      label="STAGIONALITA"
                    ></v-select-->
                    <v-select
                      v-model="editedItem.rsi"
                      :items="macd"
                      label="MACD"
                    ></v-select>
                    <v-select
                      v-model="editedItem.risk"
                      :items="risks"
                      label="RISCHIO"
                    ></v-select>
                    <span class="h3 btn-light">SEZIONE REPLAY</span>
                    <p style="font-size: 10px;margin: 0px;">DATA INIZIO</p>
                    <input type="date" 
                      style="border: solid 1px;font-size: 20px;width: 270px;"
                      @focusout="updateStockState('start_date',editedItem.start_date)"  
                      v-model="editedItem.start_date">
                    <p style="font-size: 10px;margin: 0px;">DATA FINE</p>
                    <input type="date" 
                      style="border: solid 1px;font-size: 20px;width: 270px;margin-bottom: 10px"
                      @focusout="updateStockState('end_date',editedItem.end_date)"  
                      v-model="editedItem.end_date">
                    <v-spacer></v-spacer>
                    <v-btn
                      style="float: left;background: yellow;color: black;"
                      @click="close"
                    >BACK</v-btn>
                    <v-btn
                      style="float: right;background: #3699FF;color: white;"
                      @click="show = !show; thresholds = !thresholds; update(editedItem)"
                    >NEXT</v-btn>
                  </v-col>
                  <!--  SEZIONE SOGLIE -->
                  <v-col
                    cols="6"
                    sm="3"
                    md="3"
                    v-show="!thresholds"
                  >
                  <span class="h3 btn-light">SEZIONE SOGLIE</span>
                    <v-text-field
                      style="padding-top:20px"
                      v-model="editedItem.margin"
                      @focusout="updateStop(editedItem)"
                      label="STOP LOSS ($)"
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="editedItem.widthLevel"
                      @focusout="updateStop(editedItem)"
                      label="AMPIEZZA LIVELLO"
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="editedItem.bronze_price"
                      @focusout="updateStop(editedItem)"
                      label="PREZZO BRONZE"
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="editedItem.silver_price"
                      @focusout="updateStop(editedItem)"
                      label="PREZZO SILVER"
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="editedItem.gold_price"
                      @focusout="updateStop(editedItem)"
                      label="PREZZO GOLD"
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="editedItem.stop_bronze_price"
                      label="STOP PREZZO BRONZE"
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="editedItem.stop_silver_price"
                      label="STOP PREZZO SILVER"
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="editedItem.stop_gold_price"
                      label="STOP PREZZO GOLD"
                    >
                    </v-text-field>
                    <v-text-field v-if="editedItem.asset=='Stocks'"
                      v-model="editedItem.stock_multiplier"
                      @focusout="updateStop(editedItem)"
                      label="N° CONTRATTI"
                    >
                    </v-text-field>
                    <v-spacer></v-spacer>
                    <v-btn
                      style="float: left;background: #3699FF;color: white;"
                      @click="thresholds = !thresholds; show = !show"
                    >
                      PREV
                    </v-btn>
                    <v-btn
                      style="float: right;background: #3699FF;color: white;"
                      @click="levels = !levels; thresholds = !thresholds; update(editedItem)"
                    >
                      NEXT
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="3"
                    md="3"
                    v-show="!levels"
                  >
                  <span class="h3 btn-light">SEZIONE BREAKPOINTS</span>
                    <br<br>
                    <span class="h4 btn-outline-primary mt-5">SEZIONE BRONZE</span>
                    <br>
                    <div style="background:#ffe5b6">
                      <v-textarea
                        style="padding-top: 10px !important;"
                        rows="5"
                        v-model="editedItem.message_bronze_break"
                        label="MESSAGE"
                      >
                      </v-textarea>
                      <!--v-text-field
                        v-model="editedItem.title_bronze_break"
                        label="TITLE"
                      >
                      </v-text-field>
                      <v-select
                        v-model="editedItem.type_bronze_break"
                        :items="breakpoint_type"
                        label="TYPE"
                      ></v-select-->
                      <input type="date" 
                      style="border: solid 1px;font-size: 20px;width: 270px;margin-bottom: 10px"
                      @focusout="updateStockState('bronze_break',editedItem.date_bronze_break)"  
                      v-model="editedItem.date_bronze_break">
                    </div>
                    <v-spacer></v-spacer>
                    <span class="h4 btn-outline-primary mt-5">SEZIONE SILVER</span>
                    <br>
                    <div style="background:#F3F6F9">
                      <v-textarea
                        style="padding-top: 10px !important;"
                        rows="5"
                        v-model="editedItem.message_silver_break"
                        label="MESSAGGIO"
                      >
                      </v-textarea>
                      <!--v-text-field
                        v-model="editedItem.title_silver_break"
                        label="TITOLO"
                      >
                      </v-text-field>
                      <v-select
                        v-model="editedItem.type_silver_break"
                        :items="breakpoint_type"
                        label="TIPO"
                      ></v-select-->
                      <input type="date" 
                      style="border: solid 1px;font-size: 20px;width: 270px;margin-bottom: 10px"
                      @focusout="updateStockState('silver_break',editedItem.date_silver_break)"  
                      v-model="editedItem.date_silver_break">
                    </div>
                    <v-spacer></v-spacer>
                    <span class="h4 btn-outline-primary mt-5">SEZIONE GOLD</span>
                    <br>
                    <div style="background:#f3f7029c">
                      <v-textarea
                        style="padding-top: 10px !important;"
                        rows="5"
                        v-model="editedItem.message_gold_break"
                        label="MESSAGGIO"
                      >
                      </v-textarea>
                      <!--v-text-field
                        v-model="editedItem.title_gold_break"
                        label="TITOLO"
                      >
                      </v-text-field>
                      <v-select
                        v-model="editedItem.type_gold_break"
                        :items="breakpoint_type"
                        label="TIPO"
                      ></v-select-->
                       <input type="date" 
                      style="border: solid 1px;font-size: 20px;width: 270px;margin-bottom: 10px"
                      @focusout="updateStockState('gold_break',editedItem.date_gold_break)"  
                      v-model="editedItem.date_gold_break">
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn
                      style="float: left;background: #3699FF;color: white;"
                      @click="levels = !levels; thresholds = !thresholds"
                    >
                      PREV
                    </v-btn>
                    <v-btn
                      style="float: right;background: #3699FF;color: white;"
                      @click="levels = !levels; traderbot= !traderbot; updateStockState('bronze_break',editedItem.date_bronze_break);updateStockState('silver_break',editedItem.date_silver_break);updateStockState('gold_break',editedItem.date_gold_break)"
                    >
                      NEXT
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    v-show="!traderbot"
                  >
                  <span class="h3 btn-light">SEZIONE TRADERBOT</span>
                    <br<br>
                    <br>
                    <div class="row">
                      <div class="col-6">
                        <span class="h3 btn-light">MESSAGGIO INIZIALE</span>
                        <vue-editor id="editor1" v-model="editor1Content" :editorToolbar="customToolbar"></vue-editor>
                      </div>
                      <div class="col-6">
                        <span class="h3 btn-light">MESSAGGIO FINALE</span>
                        <vue-editor id="editor2" v-model="editor2Content" :editorToolbar="customToolbar"></vue-editor>
                        <!--v-textarea
                          rows="15"
                          style="border: solid 1px gray;padding-top: 10px !important;"
                          v-model="editedItem.traderbot_message_result"
                          label="MESSAGGIO FINALE"
                        >
                        </v-textarea-->
                      </div>
                    </div>
                    <div style="background:#ffe5b6;display:none">
                      <v-textarea
                        v-model="editedItem.traderbot_message_bronze"
                        label="BRONZE MESSAGE"
                      >
                      </v-textarea>
                    </div>
                    <v-spacer></v-spacer>
                    <br>
                    <div style="background:#F3F6F9;display:none">
                     <v-textarea
                        v-model="editedItem.traderbot_message_silver"
                        label="SILVER MESSAGE"
                      >
                      </v-textarea>
                    </div>
                    <v-spacer></v-spacer>
                    <br>
                    <div style="background:#f3f7029c;display:none">
                      <v-textarea
                        v-model="editedItem.traderbot_message_gold"
                        label="GOLD MESSAGE"
                      >
                      </v-textarea>
                    </div>
                    <v-spacer></v-spacer>
                    <br>
                    <div style="background:#ff49499c;display:none">
                      <v-textarea
                        v-model="editedItem.traderbot_message_not_interested"
                        label="NOT INTERESTED MESSAGE"
                      >
                      </v-textarea>
                    </div>
                    <br><br>
                    <v-btn
                      style="float: left;background: #3699FF;color: white;"
                      @click="levels = !levels; traderbot= !traderbot"
                    >PREV</v-btn>
                    <v-btn v-if="operation!='Updated'" 
                      style="float: right;background: #3699FF;color: white;"
                      @click="save"
                    >SAVE</v-btn>
                    <v-btn v-if="operation=='Updated'" 
                      style="float: right;background: #3699FF;color: white;"
                      @click="save"
                    >UPDATE</v-btn>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="3"
                    md="9"
                    v-show="traderbot"
                  >
                    <stock-chart-output 
                      v-bind:windowHeight="'700'"
                      v-bind:ref="'playback'"
                      v-bind:namespace="namespaceStocks"
                      style="border: 1px solid darkgray;">
                    </stock-chart-output>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          fullscreen
          v-model="dialogOptions"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="m-2"
              v-bind="attrs"
              v-on="on"
            >
              New Options Exercise
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span id="optionsTitle" class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="6"
                    sm="3"
                    md="3"
                    v-show="show"
                  >
                  <span class="h3 btn-light">SEZIONE PRINCIPALE  (Opt.)</span>
                    <v-text-field
                      class="mt-4"
                      v-model="editedItem.saveName"
                      label="NOME"
                      @input="v => { editedItem.saveName = v.toUpperCase()}"
                    ></v-text-field>
                    <v-select
                      v-model="editedItem.underlying"
                      :items="underlying"
                      label="SOTTOSTANTE"
                      @change="cleanTitle()"
                    ></v-select>
                    <v-text-field v-if="editedItem.underlying=='Future'"
                      v-model="editedItem.title"
                      label="TICKER"
                      @input="v => { editedItem.title = v.toUpperCase()}"
                      @focusout="updateState()"
                    ></v-text-field>
                    <v-text-field v-if="editedItem.underlying=='Azionario'"
                      v-model="editedItem.title"
                      label="TICKER"
                      @input="v => { editedItem.title = v.toUpperCase()}"
                      @focusout="updateStockState()"
                    ></v-text-field>
                    <v-select v-if="editedItem.underlying=='Future'"
                      v-model="editedItem.sector"
                      :items="sectors"
                      label="SETTORE"
                    ></v-select>
                    <v-select v-if="editedItem.underlying=='Azionario'"
                      v-model="editedItem.sector"
                      :items="stockSectors"
                      label="SETTORE"
                    ></v-select>
                    <v-select v-if="editedItem.underlying=='Future'"
                      v-model="editedItem.buySell"
                      :items="buySell"
                      @change="updateState()"
                      @focusout="updateStop(editedItem)"
                      label="POSIZIONE"
                    ></v-select>
                    <v-select v-if="editedItem.underlying=='Azionario'"
                      v-model="editedItem.buySell"
                      :items="buySell"
                      @change="updateStockState()"
                      @focusout="updateStop(editedItem)"
                      label="POSIZIONE"
                    ></v-select>
                    <v-select 
                      v-model="editedItem.strategy"
                      :items="stockOptions"
                      label="TIPO"
                    ></v-select>
                    <!--v-select
                      v-model="editedItem.seasonal"
                      :items="seasonalities"
                      label="STAGIONALITA"
                    ></v-select-->
                    <v-select
                      v-model="editedItem.rsi"
                      :items="rsis"
                      label="RSI"
                    ></v-select>
                    <v-select
                      v-model="editedItem.risk"
                      :items="risks"
                      label="RISCHIO"
                    ></v-select>
                    <span class="h3 btn-light">SEZIONE REPLAY</span>
                    <div v-if="editedItem.underlying=='Future'">
                      <p style="font-size: 10px;margin: 0px;">DATA INIZIO</p>
                      <input type="date" 
                        style="border: solid 1px;font-size: 20px;width: 270px;"
                        @focusout="updateState('start_date',editedItem.start_date)"  
                        v-model="editedItem.start_date">
                      <p style="font-size: 10px;margin: 0px;">DATA FINE</p>
                      <input type="date" 
                        style="border: solid 1px;font-size: 20px;width: 270px;margin-bottom: 10px"
                        @focusout="updateState('end_date',editedItem.end_date)"  
                        v-model="editedItem.end_date">
                    </div>
                    <div v-if="editedItem.underlying=='Azionario'">
                      <p style="font-size: 10px;margin: 0px;">DATA INIZIO</p>
                      <input type="date" 
                        style="border: solid 1px;font-size: 20px;width: 270px;"
                        @focusout="updateStockState('start_date',editedItem.start_date)"  
                        v-model="editedItem.start_date">
                      <p style="font-size: 10px;margin: 0px;">DATA FINE</p>
                      <input type="date" 
                        style="border: solid 1px;font-size: 20px;width: 270px;margin-bottom: 10px"
                        @focusout="updateStockState('end_date',editedItem.end_date)"  
                        v-model="editedItem.end_date">
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn
                      style="float: left;background: yellow;color: black;"
                      @click="close"
                    >BACK</v-btn>
                    <v-btn
                      style="float: right;background: #3699FF;color: white;"
                      @click="show = !show; thresholds = !thresholds; update(editedItem)"
                    >NEXT</v-btn>
                  </v-col>
                  <!--  SEZIONE SOGLIE -->
                  <v-col
                    cols="6"
                    sm="3"
                    md="3"
                    v-show="!thresholds"
                  >
                  <span class="h3 btn-light">STRATEGY SECTION</span>
                    <v-text-field
                      style="padding-top:20px"
                      v-model="editedItem.margin"
                      @focusout="updateStop(editedItem)"
                      label="STOP LOSS ($)"
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="editedItem.widthLevel"
                      @focusout="updateStop(editedItem)"
                      label="AMPIEZZA LIVELLO"
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="editedItem.strike1"
                      @focusout="updateStop(editedItem)"
                      label="STRIKE 1"
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="editedItem.strike2"
                      @focusout="updateStop(editedItem)"
                      label="STRIKE 2"
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="editedItem.strike3"
                      @focusout="updateStop(editedItem)"
                      label="STRIKE 3"
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="editedItem.strike4"
                      @focusout="updateStop(editedItem)"
                      label="STRIKE 4"
                    >
                    </v-text-field>
                     <v-text-field
                      v-model="editedItem.prize"
                      label="PREMIO"
                    >
                    </v-text-field>
                    <v-select
                        v-model="editedItem.event"
                        :items="breakpoint_type"
                        label="TYPE"
                      ></v-select>
                    <v-spacer></v-spacer>
                    <v-btn
                      style="float: left;background: #3699FF;color: white;"
                      @click="thresholds = !thresholds; show = !show"
                    >
                      PREV
                    </v-btn>
                    <v-btn
                      style="float: right;background: #3699FF;color: white;"
                      @click="traderbot= !traderbot; thresholds = !thresholds; update(editedItem)"
                    >
                      NEXT
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="3"
                    md="3"
                    v-show="!levels"
                  >
                  <span class="h3 btn-light">SEZIONE BREAKPOINTS</span>
                    <br<br>
                    <span class="h4 btn-outline-primary mt-5">SEZIONE BRONZE</span>
                    <br>
                    <div style="background:#ffe5b6">
                      <v-textarea
                        style="padding-top: 10px !important;"
                        rows="5"
                        v-model="editedItem.message_bronze_break"
                        label="MESSAGE"
                      >
                      </v-textarea>
                      <!--v-text-field
                        v-model="editedItem.title_bronze_break"
                        label="TITLE"
                      >
                      </v-text-field>
                      <v-select
                        v-model="editedItem.type_bronze_break"
                        :items="breakpoint_type"
                        label="TYPE"
                      ></v-select-->
                      <input  v-if="editedItem.underlying!='Azionario'" type="date" 
                      style="border: solid 1px;font-size: 20px;width: 270px;margin-bottom: 10px"
                      @focusout="updateState('bronze_break',editedItem.date_bronze_break)"  
                      v-model="editedItem.date_bronze_break">
                      <input  v-if="editedItem.underlying=='Azionario'" type="date" 
                      style="border: solid 1px;font-size: 20px;width: 270px;margin-bottom: 10px"
                      @focusout="updateState('bronze_break',editedItem.date_bronze_break)"
                      v-model="editedItem.date_bronze_break">
                    </div>
                    <v-spacer></v-spacer>
                    <span class="h4 btn-outline-primary mt-5">SEZIONE SILVER</span>
                    <br>
                    <div style="background:#F3F6F9">
                      <v-textarea
                        style="padding-top: 10px !important;"
                        rows="5"
                        v-model="editedItem.message_silver_break"
                        label="MESSAGE"
                      >
                      </v-textarea>
                      <!--v-text-field
                        v-model="editedItem.title_silver_break"
                        label="TITLE"
                      >
                      </v-text-field>
                      <v-select
                        v-model="editedItem.type_silver_break"
                        :items="breakpoint_type"
                        label="TYPE"
                      ></v-select-->
                      <input  v-if="editedItem.underlying!='Azionario'" type="date" 
                      style="border: solid 1px;font-size: 20px;width: 270px;margin-bottom: 10px"
                      @focusout="updateState('silver_break',editedItem.date_silver_break)"  
                      v-model="editedItem.date_silver_break">
                      <input  v-if="editedItem.underlying=='Azionario'" type="date" 
                      style="border: solid 1px;font-size: 20px;width: 270px;margin-bottom: 10px"
                      @focusout="updateState('silver_break',editedItem.date_silver_break)"
                      v-model="editedItem.date_silver_break">
                    </div>
                    <v-spacer></v-spacer>
                    <span class="h4 btn-outline-primary mt-5">SEZIONE GOLD</span>
                    <br>
                    <div style="background:#f3f7029c">
                      <v-textarea
                        style="padding-top: 10px !important;"
                        rows="5"
                        v-model="editedItem.message_gold_break"
                        label="MESSAGE"
                      >
                      </v-textarea>
                      <!--v-text-field
                        v-model="editedItem.title_gold_break"
                        label="TITLE"
                      >
                      </v-text-field>
                      <v-select
                        v-model="editedItem.type_gold_break"
                        :items="breakpoint_type"
                        label="TYPE"
                      ></v-select-->
                     <input  v-if="editedItem.underlying!='Azionario'" type="date" 
                      style="border: solid 1px;font-size: 20px;width: 270px;margin-bottom: 10px"
                      @focusout="updateState('gold_break',editedItem.date_gold_break)"  
                      v-model="editedItem.date_gold_break">
                      <input  v-if="editedItem.underlying=='Azionario'" type="date" 
                      style="border: solid 1px;font-size: 20px;width: 270px;margin-bottom: 10px"
                      @focusout="updateState('gold_break',editedItem.date_gold_break)"
                      v-model="editedItem.date_gold_break">
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn
                      style="float: left;background: #3699FF;color: white;"
                      @click="levels = !levels; thresholds = !thresholds"
                    >
                      PREV
                    </v-btn>
                    <v-btn
                      style="float: right;background: #3699FF;color: white;"
                      @click="levels = !levels; traderbot= !traderbot; updateState(editedItem)"
                    >
                      NEXT
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    v-show="!traderbot"
                  >
                  <span class="h3 btn-light">SEZIONE TRADERBOT</span>
                    <br<br>
                    <br>
                    <div class="row">
                      <div class="col-6">
                        <span class="h3 btn-light">MESSAGGIO INIZIALE</span>
                        <vue-editor id="editor1" v-model="editor1Content" :editorToolbar="customToolbar"></vue-editor>
                      </div>
                      <div class="col-6">
                        <span class="h3 btn-light">MESSAGGIO FINALE</span>
                        <vue-editor id="editor2" v-model="editor2Content" :editorToolbar="customToolbar"></vue-editor>
                        <!--v-textarea
                          rows="15"
                          style="border: solid 1px gray;padding-top: 10px !important;"
                          v-model="editedItem.traderbot_message_result"
                          label="MESSAGGIO FINALE"
                        >
                        </v-textarea-->
                      </div>
                    </div>
                    <div style="background:#ffe5b6;display:none">
                      <v-textarea
                        v-model="editedItem.traderbot_message_bronze"
                        label="BRONZE MESSAGE"
                      >
                      </v-textarea>
                    </div>
                    <v-spacer></v-spacer>
                    <br>
                    <div style="background:#F3F6F9;display:none">
                     <v-textarea
                        v-model="editedItem.traderbot_message_silver"
                        label="SILVER MESSAGE"
                      >
                      </v-textarea>
                    </div>
                    <v-spacer></v-spacer>
                    <br>
                    <div style="background:#f3f7029c;display:none">
                      <v-textarea
                        v-model="editedItem.traderbot_message_gold"
                        label="GOLD MESSAGE"
                      >
                      </v-textarea>
                    </div>
                    <v-spacer></v-spacer>
                    <br>
                    <div style="background:#ff49499c;display:none">
                      <v-textarea
                        v-model="editedItem.traderbot_message_not_interested"
                        label="NOT INTERESTED MESSAGE"
                      >
                      </v-textarea>
                    </div>
                    <br><br>
                    <v-btn
                      style="float: left;background: #3699FF;color: white;"
                      @click="levels = !levels; traderbot= !traderbot"
                    >PREV</v-btn>
                    <v-btn v-if="operation!='Updated'" 
                      style="float: right;background: #3699FF;color: white;"
                      @click="save"
                    >SAVE</v-btn>
                    <v-btn v-if="operation=='Updated'" 
                      style="float: right;background: #3699FF;color: white;"
                      @click="save"
                    >UPDATE</v-btn>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="9"
                    md="9"
                    v-show="traderbot"
                  >
                     <basic-chart-output v-if="editedItem.underlying=='Future'"
                      v-bind:windowHeight="'700'"
                      v-bind:ref="'playback'"
                      v-bind:namespace="namespace"
                      style="border: 1px solid darkgray;"
                    >FUTURE</basic-chart-output>
                    <stock-chart-output v-if="editedItem.underlying=='Azionario'"
                      v-bind:windowHeight="'700'"
                      v-bind:ref="'playback'"
                      v-bind:namespace="namespaceStocks"
                      style="border: 1px solid darkgray;">AZIONI
                    </stock-chart-output>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon v-if="item.status==undefined"
        small
        class="mr-2"
        @click="publish(item)"
      >mdi-send
      </v-icon>
      <v-icon v-if="item.asset=='Commodities' && (item.block==undefined || role=='superadmin')"
        small
        class="mr-2"
        @click="editCommoditiesItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon v-if="item.asset=='Commodities' && (item.block==undefined || role=='superadmin')"
        small
        @click="deleteCommoditiesItem(item)"
      >
        mdi-delete
      </v-icon>
      <v-icon v-if="item.asset=='Stocks' && (item.block==undefined || role=='superadmin')"
        small
        class="mr-2"
        @click="editStocksItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon v-if="item.asset=='Stocks' && (item.block==undefined || role=='superadmin')"
        small
        @click="deleteStocksItem(item)"
      >
        mdi-delete
      </v-icon>
      <v-icon v-if="item.asset=='Options' && (item.block==undefined || role=='superadmin')"
        small
        class="mr-2"
        @click="editOptionsItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon v-if="item.asset=='Options' && (item.block==undefined || role=='superadmin')"
        small
        @click="deleteOptionsItem(item)"
      >
        mdi-delete
      </v-icon>
      <v-icon v-if="role=='superadmin'"
        small
        class="ml-2"
        @click="blockItem(item)"
      >
        mdi-lock
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn>
    </template>
  </v-data-table>
  </v-card>
  </div>
</template>

<script>
import {tickerToGeneralForm} from "../../js/main";
import {defaultItem, defaultInitialState, defaultInitialStockState} from "../../js/chartslab";
import basicChartOutput from "@/components/programs/BasicChart/basic-chart-output";
import stockChartOutput from "@/components/programs/StockChart/stock-chart-output";
import * as fb from '../../firebase';
// Basic Use - Covers most scenarios
import { VueEditor } from "vue2-editor";

  export default {
    components: {
      basicChartOutput,
      stockChartOutput,
      VueEditor
    },
    data: () => ({
      editor1Content: "<p>Scrivere la descrizione iniziale</p>",
      editor2Content: "<p>Scrivere la descrizione finale</p>",
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }]
      ],
      initialState : defaultInitialState(),
      initialStockState :defaultInitialStockState(),
      show: true,
      thresholds: true,
      levels: true,
      traderbot: true,
      operation: '',
      namespace: "generalFormTab1",
      namespaceStocks: "generalFormTab2",
      buySell: ['Buy', 'Sell'],
      strategies: ['Future', 'Calendar','Butterfly','Condor'],
      stockStrategies: ['Stock', 'Pair Trading'],
      stockOptions: ['Naked', 'Vertical','Butterfly','Condor'],
      types: ['Not Available', 'Intramarket', 'Intermarket'],
      seasonalities: ['Rialzista', 'Ribassista'],
      rsis: ['Basso','Medio','Alto'],
      macd: ['Positivo','Negativo','Neutro'],
      risks: ['Basso','Medio','Alto'],
      deadlines: ['Estive','Invernali'],
      sectors: ['Valute','Energetici','Granaglie','Indici','Tassi Interesse','Carni','Metalli','Coloniali'],
      stockSectors: ['ETF', 'Tsx Comp', 'S&P 500', 'Russell 2000', 'Nasdaq Comp', 'Dj Transport', 'Emerging Market', 'Volatility Index', 'Treasury Bond', 'Us Dollar Index', 'Energy', 'Materials', 'Industrials', 'Semiconductors', 'Consumer Discretionary', 'Consumer Staples', 'Health Care', 'Financial', 'Communication', 'Technology', 'Utilities', 'Agriculture', 'Airlines', 'Autos', 'Banks', 'Biotechs', 'Chemicals', 'Gas Utilities', 'Home Building', 'Household Goods', 'Metals and Mining', 'Oil Exploration', 'Oil Services', 'Pharmaceuticals', 'Railroads', 'Real Estate', 'Retail', 'Telecom'],
      prices : ['Not Available','Contango','Backwardation'],
      underlying: ['Azionario','Future'],
      breakpoint_type: ['','Take Profit','Stop Loss'],
      dialogCommodities: false,
      dialogStocks: false,
      dialogOptions: false,
      dialogDelete: false,
      search: '',
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          visible: false,
          value: 'id',
        },
        { text: 'ASSET', value: 'asset' },
        { text: 'NAME', value: 'saveName' },
        { text: 'TICKER', value: 'title' },
        { text: 'STRATEGY', value: 'strategy' },
        { text: 'DATA INIZIO', value: 'playback.start' },
        { text: 'DATA FINE', value: 'playback.end' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      records: [],
      editedIndex: -1,
      editedItem: defaultItem(),
      defaultItem: defaultItem(),
      valid: true,
      nameRules: [
        v => !!v || 'Field is required',
      ],
    }),
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Exercise' : 'Edit Exercise'
      },
      role() {
        return this.$store.state.user.role;
      }
    },
    watch: {
      dialogCommodities (val) {
        // Future Chart
        if(!val) {
          this.operation='Save';
          this.editedItem = defaultItem();
          this.initialState = defaultInitialState();
          this.$store.commit(this.namespace + "/setGeneralForm", this.initialState);
          this.$store.commit(this.namespace + "/setModuleName", this.namespace);
          this.$store.dispatch(this.namespace + "/getContractListsFromServer");
        }
        this.editedItem.asset = 'Commodities';
        this.initialState.asset = 'Commodities';
        val || this.close()
      },
      dialogStocks (val) {
        // Stock Chart
        if(!val) {
          this.operation='Save';
          this.editedItem = defaultItem();
          this.initialStockState = defaultInitialStockState();
          this.$store.commit(this.namespaceStocks + "/setGeneralForm", this.initialStockState);
          this.$store.commit(this.namespaceStocks + "/setModuleName", this.namespaceStocks);
          this.$store.dispatch(this.namespaceStocks + "/getContractListsFromServer");
        }
        this.editedItem.asset = 'Stocks';
        this.initialStockState.asset = 'Stocks';
        val || this.close()
      },
      dialogOptions (val) {
        // Options Future Chart
        if(!val && this.editedItem.underlying=='Future') {
          this.operation='Save';
          this.editedItem = defaultItem();
          this.initialState = defaultInitialState();
          this.$store.commit(this.namespace + "/setGeneralForm", this.initialState);
          this.$store.commit(this.namespace + "/setModuleName", this.namespace); 
          this.$store.dispatch(this.namespace + "/getContractListsFromServer");
          this.initialStockState = defaultInitialStockState();
          this.$store.commit(this.namespaceStocks + "/setGeneralForm", this.initialStockState);
          this.$store.commit(this.namespaceStocks + "/setModuleName", this.namespaceStocks);
          this.$store.dispatch(this.namespaceStocks + "/getContractListsFromServer");
        }
        // Options Stock Chart
        if(!val && this.editedItem.underlying=='Azionario') {
          this.operation='Save';
          this.editedItem = defaultItem();
          this.initialStockState = defaultInitialStockState();
          this.$store.commit(this.namespaceStocks + "/setGeneralForm", this.initialStockState);
          this.$store.commit(this.namespaceStocks + "/setModuleName", this.namespaceStocks);
          this.$store.dispatch(this.namespaceStocks + "/getContractListsFromServer");
          this.initialState = defaultInitialState();
          this.$store.commit(this.namespace + "/setGeneralForm", this.initialState);
          this.$store.commit(this.namespace + "/setModuleName", this.namespace); 
          this.$store.dispatch(this.namespace + "/getContractListsFromServer");
        }
        this.editedItem.asset = 'Options';
        this.initialState.asset = 'Options';
        this.initialStockState.asset = 'Options';
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      }
    },
    created () {
      this.initialize();
      console.log("Playback.vue created() starting.");
      console.log("this.initialState=", this.initialState);
    },
    methods: {
      validateStep1 (editedItem) {
        var result = this.$refs.form.validate();
        if(result) {
          this.show = !this.show; 
          this.thresholds = !this.thresholds; 
          this.update(editedItem);
        } else {
          scrollTop('commodities');
        }
      },
      validateStep2 (editedItem) {
        var result = this.$refs.form2.validate();
        if(result) {
          this.levels = !this.levels; 
          this.thresholds = !this.thresholds; 
          this.update(editedItem);
        } else {
          scrollTop('commodities');
        }
      },
      validateStep3 () {
        var result = this.$refs.form3.validate();
        if(result) {
          this.save();
        } else {
          scrollTop('commodities');
        }
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
      cleanTitle() {
        this.editedItem.title='';
      },
      updateStop(item) {
        var namespace = 'generalFormTab1';
        var state = 'initialState';
        if(item.asset=='Stocks') {
          if(item.stock_multiplier=="") item.stock_multiplier=1;
          namespace = 'generalFormTab2';state = ['initialStockState'];
        }
        if(item.asset=='Options') {
          namespace = 'generalFormTab1';
          if(item.underlying=='Azionario') {
            state = 'initialStockState';
            namespace = 'generalFormTab2';
          }
        }
        var price;
        item.state = state;
        this[state].chartParameters.levels = [];
        if(this.editedItem.buySell=='buy' || this.editedItem.buySell=='Buy' ) {
          if(this.editedItem.bronze_price!=undefined && this.editedItem.bronze_price.toString()!="") {
            price = this.editedItem.bronze_price;
            setLevel(this,'Buy','bronze',price,item);
          }
          if(this.editedItem.silver_price!=undefined && this.editedItem.silver_price.toString()!="") {
            price = this.editedItem.silver_price;
            setLevel(this,'Buy','silver',price,item);
          }
          if(this.editedItem.gold_price!=undefined && this.editedItem.gold_price.toString()!="") {
            price = this.editedItem.gold_price;
            setLevel(this,'Buy','gold',price,item);
          }
        } else {
          if(this.editedItem.bronze_price!=undefined &&   this.editedItem.bronze_price.toString()!="") {
            price = this.editedItem.bronze_price;
            setLevel(this,'Sell','bronze',price,item);
          }
          if(this.editedItem.silver_price!=undefined && this.editedItem.silver_price.toString()!="") {
            price = this.editedItem.silver_price;
            setLevel(this,'Sell','silver',price,item);
          }
          if(this.editedItem.gold_price!=undefined && this.editedItem.gold_price.toString()!="") {
            price = this.editedItem.gold_price;
            setLevel(this,'Sell','gold',price,item);
          }
        }
        // FOR OPTIONS
        if(this.editedItem.strike1!=undefined && this.editedItem.strike1.toString()!="") {
          setLevel(this,'Sell','strike1',this.editedItem.strike1,item);
        }
        if(this.editedItem.strike2!=undefined && this.editedItem.strike2.toString()!="") {
          setLevel(this,'Sell','strike2',this.editedItem.strike2,item);
        }
        if(this.editedItem.strike3!=undefined && this.editedItem.strike3.toString()!="") {
          setLevel(this,'Sell','strike3',this.editedItem.strike3,item);
        }
        if(this.editedItem.strike4!=undefined && this.editedItem.strike4.toString()!="") {
          setLevel(this,'Sell','strike4',this.editedItem.strike4,item);
        }
        //this.editedItem.addHorizontalLine = true;
        this.$store.commit(namespace + '/setGeneralForm', this[state]);
        this.$store.commit(namespace + "/setModuleName", namespace);
        this.$store.dispatch(namespace + '/getContractListsFromServer');
      },
      update(item) {
        var state = 'initialState';
        if(item.asset=='Stocks') state = ['initialStockState'];
        if(item.asset=='Options' && item.underlying=='Azionario') state = 'initialStockState';
        this[state].saveName = this.editedItem.saveName;
        this[state].sector = this.editedItem.sector;
        this[state].strategy = this.editedItem.strategy;
        this[state].type = this.editedItem.type==undefined ? '' : this.editedItem.type;
        this[state].seasonal = this.editedItem.seasonal;
        this[state].risk = this.editedItem.risk;
        this[state].rsi = this.editedItem.rsi;
        this[state].deadlines = this.editedItem.deadlines;
        // SEZIONE SOGLIE
        this[state].margin = this.editedItem.margin;
        var level;var gap;
        if(item.asset!='Options') {
          if(this.editedItem.bronze_price!=undefined && this.editedItem.bronze_price!="") {
            level = new Object();
            var digits = this.editedItem.bronze_price.toString().indexOf(".")>=0 ? this.editedItem.bronze_price.toString().split(".")[1].length : 0;
            gap = 2/ (digits==0 ? 1 : Math.pow(10,digits));
            gap = parseFloat(this.editedItem.widthLevel);
            level["from"] = parseFloat(this.editedItem.bronze_price)-gap;
            level["price"] = parseFloat(this.editedItem.bronze_price);
            level["to"] = parseFloat(this.editedItem.bronze_price)+gap;
            level["name"] = "horizontal-bronze-series";
            level["color"] = "orange";
            //this[state].chartParameters.levels.push(level);
          }
          if(this.editedItem.silver_price!=undefined && this.editedItem.silver_price!="") {
            level = new Object();
            digits = this.editedItem.silver_price.toString().indexOf(".")>=0 ? this.editedItem.silver_price.toString().split(".")[1].length : 0;
            gap = 2/ (digits==0 ? 1 : Math.pow(10,digits));
            gap = parseFloat(this.editedItem.widthLevel);
            level["from"] = parseFloat(this.editedItem.silver_price)-gap;
            level["price"] = parseFloat(this.editedItem.silver_price);
            level["to"] = parseFloat(this.editedItem.silver_price)+gap;
            level["name"] = "horizontal-silver-series";
            level["color"] = "silver";
            //this[state].chartParameters.levels.push(level);
          }
          if(this.editedItem.gold_price!=undefined && this.editedItem.gold_price!="") { 
            level = new Object();
            digits = this.editedItem.gold_price.toString().indexOf(".")>=0 ? this.editedItem.gold_price.toString().split(".")[1].length : 0;
            gap = 2/ (digits==0 ? 1 : Math.pow(10,digits));
            gap = parseFloat(this.editedItem.widthLevel);
            level["from"] = parseFloat(this.editedItem.gold_price)-gap;
            level["price"] = parseFloat(this.editedItem.gold_price);
            level["to"] = parseFloat(this.editedItem.gold_price)+gap;
            level["name"] = "horizontal-gold-series";
            level["color"] = "gold";
            //this[state].chartParameters.levels.push(level);
          }
          //this.updateStop(item);
        } else { 
          if(this.editedItem.strike1!=undefined && this.editedItem.strike1!="") {
            level = new Object();
            gap = parseFloat(this.editedItem.widthLevel);
            level["from"] = parseFloat(this.editedItem.strike1)-gap;
            level["price"] = parseFloat(this.editedItem.strike1);
            level["to"] = parseFloat(this.editedItem.strike1)+gap;
            level["name"] = "horizontal-strike1-series";
            level["color"] = "darkgreen";
            //this[state].chartParameters.levels.push(level);
          }
          if(this.editedItem.strike2!=undefined && this.editedItem.strike2!="") {
            level = new Object();
            gap = parseFloat(this.editedItem.widthLevel);
            level["from"] = parseFloat(this.editedItem.strike2)-gap;
            level["price"] = parseFloat(this.editedItem.strike2);
            level["to"] = parseFloat(this.editedItem.strike2)+gap;
            level["name"] = "horizontal-strike2-series";
            level["color"] = "darkgreen";
            //this[state].chartParameters.levels.push(level);
          }
          if(this.editedItem.strike3!=undefined && this.editedItem.strike3!="") { 
            level = new Object();
            gap = parseFloat(this.editedItem.widthLevel);
            level["from"] = parseFloat(this.editedItem.strike3)-gap;
            level["price"] = parseFloat(this.editedItem.strike3);
            level["to"] = parseFloat(this.editedItem.strike3)+gap;
            level["name"] = "horizontal-strike3-series";
            level["color"] = "darkgreen";
            //this[state].chartParameters.levels.push(level);
          }
          if(this.editedItem.strike4!=undefined && this.editedItem.strike4!="") { 
            level = new Object();
            gap = parseFloat(this.editedItem.widthLevel);
            level["from"] = parseFloat(this.editedItem.strike4)-gap;
            level["price"] = parseFloat(this.editedItem.strike4);
            level["to"] = parseFloat(this.editedItem.strike4)+gap;
            level["name"] = "horizontal-strike4-series";
            level["color"] = "darkgreen";
            //this[state].chartParameters.levels.push(level);
          }
        }
      },
      updateStockState(key,value) {
          // Check title
          if(this.editedItem.title!=undefined && this.editedItem.title!='') {
            // Example 4*LOGI-16*AAPL (this.editedItem.title)
            var generalFormHelper = tickerToGeneralForm(this.editedItem.title,"stock");
            this.initialStockState.unitMove = generalFormHelper.unitMove;
            this.initialStockState.selected = generalFormHelper.selected;
            this.initialStockState.legs = generalFormHelper.legs;
            this.initialStockState.mult = generalFormHelper.mult;
            this.initialStockState.p = generalFormHelper.p;
            this.initialStockState.instrument = generalFormHelper.instrument;
            this.initialStockState.title = this.editedItem.title;
            this.initialStockState.stockArray=generalFormHelper.symbolArray;
            this.initialStockState.dataSource = "barchart";
            updateKey(this.editedItem,this.initialStockState,key,value);
            this.initialStockState.buySell = this.editedItem.buySell=='Buy' ? 'buy' : 'sell';
            this.initialStockState.showTradingPeriod=true;
            this.initialStockState.open = this.editedItem.start_date;
            this.initialStockState.close = this.editedItem.end_date;
            this.$store.commit(this.namespaceStocks + '/setGeneralForm', this.initialStockState);
            this.$store.dispatch(this.namespaceStocks + '/getContractListsFromServer');
            //if(key=='start_date' || key=='end_date') this.$root.$emit('playback_rewind');
            console.log("updateState() starting.");
          } // end check title
      },
      updateState(key,value) {
          console.log("updateState() starting.");
          // Check title
          if(this.editedItem.title!=undefined && this.editedItem.title!='') {
            var generalFormHelper = tickerToGeneralForm(this.editedItem.title);
            this.initialState.unitMove = generalFormHelper.unitMove;
            this.initialState.selected = generalFormHelper.selected;
            this.initialState.legs = generalFormHelper.legs;
            this.initialState.mult = generalFormHelper.mult;
            this.initialState.p = generalFormHelper.p;
            this.initialState.sampleContract = generalFormHelper.sampleContract;
            this.initialState.title = this.editedItem.title;
            updateKey(this.editedItem,this.initialState,key,value);
            this.initialState.buySell = this.editedItem.buySell=='Buy' ? 'buy' : 'sell';
            this.initialState.showTradingPeriod=true;
            this.initialState.open = this.editedItem.start_date;
            this.initialState.close = this.editedItem.end_date;
            this.$store.commit(this.namespace + '/setGeneralForm', this.initialState);
            this.$store.dispatch(this.namespace + '/getContractListsFromServer');
            console.log("updateState() starting.");
            //if(key=='start_date' || key=='end_date') this.$root.$emit('playback_rewind');
          } // end check title
      },
      initialize () {
       this.records = [];
       fb.db.collection('saved-charts')
          .doc('chartslab@system.com').collection('charts').get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                var obj = doc.data().generalForm;
                obj['id'] = doc.id;
                this.records.push(obj);
            });
        });
      },
      publish (item) {
        var editedItem = this.records.find(x=>x.id == item.id);
        editedItem.status = 'public';
        var generalForm = editedItem;
        fb.db.collection('saved-charts')
          .doc('chartslab@system.com')
          .collection('charts')
          .doc(item.id)
          .set({generalForm}).then(() => {
            location.reload();
          });
      },
      blockItem (item) {
        var editedItem = this.records.find(x=>x.id == item.id);
        editedItem.block = true;
        var generalForm = editedItem;
        fb.db.collection('saved-charts')
          .doc('chartslab@system.com')
          .collection('charts')
          .doc(item.id)
          .set({generalForm}).then(() => {
            location.reload();
          });
      },
      editCommoditiesItem (item) {
        this.dialogCommodities = true;
        this.editedItem = this.records.find(x=>x.id == item.id);
        this.operation='Updated';
        this.editedItem.bronze_price = this.editedItem.chartParameters.levels[0].price;
        this.editedItem.silver_price = this.editedItem.chartParameters.levels[1].price;
        this.editedItem.gold_price = this.editedItem.chartParameters.levels[2].price;
        //setLevel(this,this.editedItem.buySell,'bronze',this.editedItem.bronze_price,state);
        this.editedItem.end_date= this.editedItem.playback.end;
        this.editedItem.start_date=this.editedItem.playback.start;
        if(this.editedItem.playback.breakpoints[0]!=undefined) {
          this.editedItem.message_bronze_break = this.editedItem.playback.breakpoints[0].message;
          this.editedItem.date_bronze_break = this.editedItem.playback.breakpoints[0].date;
        }
        if(this.editedItem.playback.breakpoints[1]!=undefined) {
          this.editedItem.message_silver_break = this.editedItem.playback.breakpoints[1].message;
          this.editedItem.date_silver_break = this.editedItem.playback.breakpoints[1].date;
        }
        if(this.editedItem.playback.breakpoints[2]!=undefined) {
          this.editedItem.message_gold_break = this.editedItem.playback.breakpoints[2].message;
          this.editedItem.date_gold_break = this.editedItem.playback.breakpoints[2].date;
        }
        this.editedItem.traderbot_message_result = this.editedItem.playback.messages.result;
        this.editor1Content = this.editedItem.playback.messages.intro;
        this.editor2Content = this.editedItem.playback.messages.result;
        this.updateStop(this.editedItem);
        this.editedItem.buySell = this.editedItem.buySell=='buy' ? 'Buy' : 'Sell';
        this.updateState();
      },
      deleteCommoditiesItem (item) {
        this.editedItem = this.records.find(x=>x.id == item.id);
        this.dialogDelete = true
      },
      editStocksItem (item) {
        this.dialogStocks = true;
        this.editedItem = this.records.find(x=>x.id == item.id);
        this.operation='Updated';
        this.editedItem.bronze_price = this.editedItem.chartParameters.levels[0].price;
        this.editedItem.silver_price = this.editedItem.chartParameters.levels[1].price;
        this.editedItem.gold_price = this.editedItem.chartParameters.levels[2].price;
        //setLevel(this,this.editedItem.buySell,'bronze',this.editedItem.bronze_price,state);
        this.editedItem.end_date=this.editedItem.playback.end;
        this.editedItem.start_date=this.editedItem.playback.start;
        if(this.editedItem.playback.breakpoints[0]!=undefined) {
          this.editedItem.message_bronze_break = this.editedItem.playback.breakpoints[0].message;
          this.editedItem.date_bronze_break = this.editedItem.playback.breakpoints[0].date;
        }
        if(this.editedItem.playback.breakpoints[1]!=undefined) {
          this.editedItem.message_silver_break = this.editedItem.playback.breakpoints[1].message;
          this.editedItem.date_silver_break = this.editedItem.playback.breakpoints[1].date;
        }
        if(this.editedItem.playback.breakpoints[2]!=undefined) {
          this.editedItem.message_gold_break = this.editedItem.playback.breakpoints[2].message;
          this.editedItem.date_gold_break = this.editedItem.playback.breakpoints[2].date;
        }
        this.editedItem.traderbot_message_result = this.editedItem.playback.messages.result;
        this.editor1Content = this.editedItem.playback.messages.intro;
        this.editor2Content = this.editedItem.playback.messages.result;

        this.updateStop(this.editedItem);
        this.editedItem.buySell = this.editedItem.buySell=='buy' ? 'Buy' : 'Sell';
        this.updateStockState();
      },
      deleteStocksItem (item) {
        this.editedIndex = this.records.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      editOptionsItem (item) {
        this.dialogOptions = true;
        this.editedItem = this.records.find(x=>x.id == item.id);
        this.operation='Updated';
        this.editedItem.end_date=this.editedItem.playback.end;
        this.editedItem.start_date=this.editedItem.playback.start;
        if(this.editedItem.chartParameters.levels[0]!=undefined)
        this.editedItem.strike1 = this.editedItem.chartParameters.levels[0].price;
        if(this.editedItem.chartParameters.levels[1]!=undefined)
        this.editedItem.strike2 = this.editedItem.chartParameters.levels[1].price;
        if(this.editedItem.chartParameters.levels[2]!=undefined)
        this.editedItem.strike3 = this.editedItem.chartParameters.levels[2].price;
        if(this.editedItem.chartParameters.levels[3]!=undefined)
        this.editedItem.strike4 = this.editedItem.chartParameters.levels[3].price;
        this.editedItem.traderbot_message_result = this.editedItem.playback.messages.result;
        this.editor1Content = this.editedItem.playback.messages.intro;
        this.editor2Content = this.editedItem.playback.messages.result;
        this.updateStop(this.editedItem);
        this.editedItem.buySell = this.editedItem.buySell=='buy' ? 'Buy' : 'Sell';
        if(this.editedItem.underlying=='Azionario') this.updateStockState();
        else this.updateState();
      },
      deleteOptionsItem (item) {
        this.editedIndex = this.records.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        fb.db.collection('saved-charts')
            .doc('chartslab@system.com')
            .collection('charts').doc(this.editedItem.id).delete().then(() => {
            console.log("Document successfully deleted!");
        }).catch((error) => {
            console.error("Error removing document: ", error);
        });
        var id = this.editedItem.id;
        this.records = this.records.filter(function( obj ) {
          return obj.id !== id;
        });
        this.closeDelete();
      },
      prev () {
        this.dialogCommodities = false
        this.dialogStocks = false
        this.dialogOptions = false

      },
      close () {
        location.reload();
        /*this.dialogCommodities = false;
        this.dialogStocks = false;
        this.dialogOptions = false;
        this.editedItem = defaultItem();
        this.show = true;
        this.thresholds = true;
        this.levels = true;
        this.traderbot = true;
        this.operation='';*/
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.records[this.editedIndex], this.editedItem)
        } else {
          var generalForm = this.initialState;
          if(this.editedItem.asset=='Stocks') generalForm = this.initialStockState;
          if(this.editedItem.asset=='Options') {
            if(this.editedItem.underlying=='Azionario') {
              generalForm = this.initialStockState;
            }
          }
          // Remove not necessary levels
          var levels = new Array();
          if(this.editedItem.asset!='Options') {
            var levelBronze = generalForm.chartParameters.levels[0];
            levelBronze.stop_price = generalForm.chartParameters.levels[1].price;
            var levelSilver = generalForm.chartParameters.levels[2];
            levelSilver.stop_price = generalForm.chartParameters.levels[3].price;
            var levelGold = generalForm.chartParameters.levels[4];
            levelGold.stop_price = generalForm.chartParameters.levels[5].price;
            if(levelBronze!=undefined) levels.push(levelBronze);
            if(levelSilver!=undefined) levels.push(levelSilver);
            if(levelGold!=undefined) levels.push(levelGold);
          } else {
            levels = this.initialStockState.chartParameters.levels;
          }
          generalForm.widthLevel = this.editedItem.widthLevel;
          generalForm.chartParameters.levels = levels;
          generalForm.prize = this.editedItem.prize;
          generalForm.type = this.editedItem.type==undefined ? '' : this.editedItem.type;
          generalForm.event = this.editedItem.event;
          generalForm.playback.start = this.editedItem.start_date;
          generalForm.playback.end = this.editedItem.end_date;
          generalForm.playback.messages = new Object();
          if(this.editedItem.stock_multiplier!=undefined) 
          generalForm.stock_multiplier = this.editedItem.stock_multiplier;
          generalForm.playback.messages["result"] = this.editor2Content
          generalForm.playback.messages["intro"] = this.editor1Content;

          if(this.operation=='Updated') {
            generalForm.created = this.editedItem.created;
            generalForm.updated = new Date();
            var breakpoints = [];
            if(this.editedItem.message_bronze_break!=undefined && this.editedItem.message_bronze_break!="") {
              generalForm.playback.breakpoints[0].message = this.editedItem.message_bronze_break;
              generalForm.playback.breakpoints[0].date = this.editedItem.date_bronze_break;
              breakpoints.push(generalForm.playback.breakpoints[0]);
            }
            if(this.editedItem.message_silver_break!=undefined && this.editedItem.message_silver_break!="") {
              generalForm.playback.breakpoints[1].message = this.editedItem.message_silver_break;
              generalForm.playback.breakpoints[1].date = this.editedItem.date_silver_break;
              //this.editedItem.playback.breakpoints[1] = generalForm.playback.breakpoints[1];
              breakpoints.push(generalForm.playback.breakpoints[1]);
            }
            if(this.editedItem.message_gold_break!=undefined && this.editedItem.message_gold_break!="") {
              generalForm.playback.breakpoints[2].message = this.editedItem.message_gold_break;
              generalForm.playback.breakpoints[2].date = this.editedItem.date_gold_break;
              //this.editedItem.playback.breakpoints[2] = generalForm.playback.breakpoints[2];
              breakpoints.push(generalForm.playback.breakpoints[2]);
            }
            generalForm.playback.breakpoints = breakpoints;
            var docRef = this.editedItem.id; 
            fb.db.collection('saved-charts')
              .doc('chartslab@system.com')
              .collection('charts')
              .doc(docRef)
              .set({generalForm})
              .then(() => {
                if(that.editedItem.underlying=='Azionario' || this.editedItem.asset=='Stocks') {
                  that.initialStockState = defaultInitialStockState();
                } else {
                  that.initialState = defaultInitialState();
                }
                //generalForm['id'] = docRef.id;
                //this.records.push(generalForm);
                location.reload();
            })
          } else {
            generalForm.created = new Date();
            fb.db.collection('saved-charts')
              .doc('chartslab@system.com')
              .collection('charts')
              .add({generalForm})
              .then((docRef) => {
                this.initialState = defaultInitialState();
                this.initialStockState = defaultInitialStockState(),
                console.log("Document written with ID: ", docRef.id);
                generalForm['id'] = docRef.id;
                //this.records.push(generalForm);
                //location.reload();
                this.close();
            })
          }
          var that = this;
        }
      }
    },
  }

function updateKey(editedItem,state,key,value) {

  var obj = new Object();
  if(key=='start_date') {
    state.playback.start = value; 
    state.open= value;
  }
  if(key=='end_date') {
    state.playback.end = value; 
    state.close= value;
  }
  if(key=='bronze_break' && value!="") {
    state.playback.breakpoints = state.playback.breakpoints.filter(function(value, index, arr){ 
        return arr[index].level!='bronze';
    });
    obj['title'] = 'Breakpoint';
    obj['message'] = editedItem.message_bronze_break;
    obj['type'] = "-"; //editedItem.type_bronze_break;
    obj['date'] = value;
    obj['level'] = 'bronze';
    state.playback.breakpoints.push(obj);
  }
  if(key=='silver_break' && value!="") {
    state.playback.breakpoints = state.playback.breakpoints.filter(function(value, index, arr){ 
        return arr[index].level!='silver';
    });
    obj['title'] = 'Breakpoint';
    obj['message'] = editedItem.message_silver_break;
    obj['type'] = "-"; //editedItem.type_silver_break;
    obj['date'] = value;
    obj['level'] = 'silver';
    state.playback.breakpoints.push(obj);
  }
  if(key=='gold_break' && value!="") {
    state.playback.breakpoints = state.playback.breakpoints.filter(function(value, index, arr){ 
        return arr[index].level!='gold';
    });
    obj['title'] = 'Breakpoint';
    obj['message'] = editedItem.message_gold_break;
    obj['type'] = "-"; //editedItem.type_gold_break;
    obj['date'] = value;
    obj['level'] = 'gold';
    state.playback.breakpoints.push(obj);
  }
}

function setLevel(that,position,level,price,item) {
  var digits = price.toString().indexOf(".")>=0 ? price.toString().split(".")[1].length : 0;
  var gap = 2/ (digits==0 ? 1 : Math.pow(10,digits));
  gap = parseFloat(price)/10;
  gap = parseFloat(that.editedItem.widthLevel);
  var i = 0;
  console.log(i);
  var color = level;
  if(level=='bronze') color = "orange";
  if(level=='silver') i = 2;
  if(level=='gold') i = 4;
  var key = 'stop_' + level + '_price';
  var keySeries = 'horizontal-' + level + '-series';
  var keyStopSeries = 'horizontal-stop-' + level + '-series';
  if(item.unitMove==undefined) item.unitMove = that[item.state].unitMove;
  // SKIP FOR OPTIONS
  if(level.indexOf("strike")>=0) {
    color = "darkgreen";
    i = parseFloat(level.replace("strike",""))-1;
    // Add level
    if(that[item.state].chartParameters.levels.find(x=>x.name==keySeries)==undefined)
    that[item.state].chartParameters.levels.push({ from: parseFloat(price)-gap, to: parseFloat(price)+gap, color: color, name: keySeries, price: parseFloat(price)});
  } else {
      // ONLY STOCKS OR AZIONARIO
      if(that.editedItem.asset=='Stocks' || that.editedItem.underlying=='Azionario') {
        that.editedItem[key] = parseFloat(parseFloat(price) - parseFloat(that.editedItem.margin)).toFixed(2);
        if(that.editedItem.asset=='Stocks') {
          if(position=='Buy')
          that.editedItem[key] = parseFloat(parseFloat(price) - (parseFloat(that.editedItem.margin)/parseFloat(that.editedItem.stock_multiplier))).toFixed(2);
          if(position=='Sell')
          that.editedItem[key] = parseFloat(parseFloat(price) + (parseFloat(that.editedItem.margin)/parseFloat(that.editedItem.stock_multiplier))).toFixed(2);
        }
      } else {
        // BUY POSIZIONE
        if(position=='Buy')
        that.editedItem[key] = parseFloat(parseFloat(price) -  parseFloat(that.editedItem.margin) / parseFloat(item.unitMove[0])).toFixed(2);
        // SELL POSIZIONE
        if(position=='Sell')
        that.editedItem[key] = parseFloat(parseFloat(price) + parseFloat(that.editedItem.margin) / parseFloat(item.unitMove[0])).toFixed(2);
      }
      
      // Add levels
      that[item.state].chartParameters.levels.push({ from: parseFloat(price)-gap, to: parseFloat(price)+gap, color: color, name: keySeries, price: parseFloat(price)});
      that[item.state].chartParameters.levels.push({ from: parseFloat(that.editedItem[key])-gap, to: parseFloat(that.editedItem[key])+gap, color: "red", name: keyStopSeries, price: parseFloat(that.editedItem[key])});
  }
}
function scrollTop(asset) {
  var element;
  if(asset=='commodities') {
    element = document.getElementById("commodTitle")
    element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  }
  if(asset=='stocks') {
    element = document.getElementById("stocksTitle")
    element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  }
  if(asset=='options') {
    element = document.getElementById("optionsTitle")
    element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  }
}
</script>
<style>
#editor1,
#editor2 {
  height: 350px;
}
table > thead > tr > th {
  font-size: 1rem !important;
}
table > tbody > tr > td {
  font-size: 1rem !important;
}
.v-text-field .v-label--active {
    left: -8px !important;
}
.accent {
  background-color: #005caf !important;
  border-color: #005caf !important;
}
textarea {
  margin-top:10px;
}
#generalFormTab1-chartDiv, #generalFormTab2-chartDiv {
  height: 700px !important;
}
.btn-light {
  background:white;
}
#generalFormTab1-playbackControl,
#generalFormTab1-playbackControl + div,
#generalFormTab2-playbackControl,
#generalFormTab2-playbackControl + div,
.amcharts-amexport-menu {
  display: none !important;
}
.v-input {
  padding: 0px !important;
}
.error--text {
  color: red !important;
}
.v-text-field {
  border: 0px solid !important;
}
</style>