/*function getWeek(date) {
  var currentdate = new Date(date<10000000000 ?  date * 1000 : date);
  var oneJan = new Date(currentdate.getFullYear(),0,1);
  var numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000));
  return Math.ceil(( currentdate.getDay() + 1 + numberOfDays) / 7);
}*/

const monthNames = ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu",
  "Lug", "Ago", "Set", "Ott", "Nov", "Dic"
];

function defaultItem() {
    var obj = {
        asset: '',
        underlying: '', 
        name: '',
        ticker: '',
        sector: '',
        buySell: 'Buy',
        strategy:'',
        seasonal:'',
        risk:'',
        title: '',
        rsi: '',
        deadlines: '',
        margin: '500',
        bronze_price: '',
        silver_price: '',
        gold_price: '',
        stop_bronze_price: '',
        stop_silver_price: '',
        stop_gold_price: '',
        title_bronze_break: '',
        message_bronze_break: '',
        date_bronze_break: '',
        type_bronze_break: '',
        title_silver_break: '',
        message_silver_break: '',
        date_silver_break: '',
        type_silver_break: '',
        title_gold_break: '',
        message_gold_break: '',
        date_gold_break: '',
        type_gold_break: '',
        prize: '',
        event:'',
        strike1: '',
        strike2: '',
        strike3: '',
        strike4: '',
        traderbot_message_bronze: '',
        traderbot_message_silver: '',
        traderbot_message_gold: '',
        traderbot_message_result: '',
        traderbot_message_not_interested: '',
        widthLevel: 1,
        start_date: '',
        end_date: '',
        stock_multiplier: 1
    };
    return obj;
}

function defaultInitialState(backoffice) {
    console.log(backoffice);
    var date = new Date();
    var yesterday = date.setDate(date.getDate() - 1);
    var obj =  {
      underlying: 'Future', 
      p: [1, -1, 1, 1],
      spreadP: 1,
      unitMove: [50, 50, 50, 50],
      legs: 1,
      sampleContract: ["S2021X", "O2021U", "MW2020U", "KW2020U"],
      stockArray: ["AAPL, Apple Inc.", "IBM, International Business Machines"],
      selected: ["S2021X", "ZCU21"], //["AAPL, Apple Inc."],
      barchartTicker: "ZSX21",
      title: "S2021X",
      mult: [1, 1, 1, 1],
      y1: 15,
      open: "2021-01-25",
      close: "2021-03-26",
      generator: "BasicBarsGenerator",
      program: "BasicCharts",
      study: "basic",
      language: "en",
      hiddenAmchartsItems: [],
      addBollinger: false,
      addSMA: false,
      smaLength: 20,
      addCOTPanel: false,
      addRSIPanel: true,
      addMACDPanel: false,
      addATRPanel: false,
      addVolumePanel: false,
      addCCIPanel: false,
      addVolumeProfile: false,
      showTradingPeriod: false,
      showAligned: false,
      addProfitLoss: false,
      overwriteShow: false,
      rsiPeriod: 7,
      cciPeriod: 20,
      volumeProfileColumns: 24,
      cciSource: "closes",
      chartScrollbarEnabled: false,
      showBullets: true,
      showPlaybackControl: true,
      addHorizontalLine: true,
      intracommodity: false,
      stockGraphType: "line",
      showSeasonals: true,
      seasonals: [5, 15],
      truncate: 3,
      buySell: "buy",
      dataSource: "csi",
      chartParameters: {
        verticalScrollbar: false,
        lastChartDate: "2020-12-31",
        balloons: false,
        skipEmptyPeriods: false,
        levels: [
        ],
        entryType: "limit",
      },
      playback: {
        start: "2021-02-25",
        end: new Date(yesterday).toISOString().split('T')[0],
        interval: 1,
        breakpoints: [
        ],
      },
      search: {},
      instrument: "future",
      user: "A4A-user0",
      password: "M1-K8xQgmR",
    }
    return obj;
}

function defaultInitialStockState(backoffice) {
    console.log(backoffice);
    var date = new Date();
    var yesterday = date.setDate(date.getDate() - 1);
    var obj = {
       "instrument": "stock",
        "legs": 1,
        "underlying": 'Azionario',
        "sampleContract": [
            "W2023H",
            "W2021K",
            "MW2020U",
            "O2020U"
        ],
        "unitMove": [
            50,
            50,
            50,
            50
        ],
        "selected": [
            "AAPL, Apple Inc."
        ],
        "intracommodity": false,
        "p": [
            1,
            -1,
            1,
            1
        ],
        "mult": [
            1,
            1,
            1,
            1
        ],
        "y1": 15,
        "generator": "BasicBarsGenerator",
        "program": "StockCharts",
        "stockGraphType": "candlestick",
        "seasonals": [
            5,
            15
        ],
        "dataSource": "barchart",
        "spreadP": 1,
        "spreadMult": 1,
        "stockArray": [
            "AMZN, Apple Inc.",
            "IBM, International Business Machines"
        ],
        "loading": false,
        "addRSIPanel": false,
        "rsiPeriod": 7,
        "addCCIPanel": false,
        "cciPeriod": 20,
        "cciSource": "closes",
        "chartScrollbarEnabled": false,
        "addMACDPanel": true,
        "addATRPanel": false,
        "addBollinger": false,
        "addSMA": true,
        "smaLength": 20,
        "addVolumePanel": false,
        "showBullets": true,
        "addCOTPanel": false,
        "addVolumeProfile": false,
        "volumeProfileColumns": 24,
        "showTradingPeriod": false,
        "showPlaybackControl": true,
        "showBreakpoints": false,
        "addProfitLoss": false,
        "addHorizontalLine": true,
        "open": "2021-01-25",
        "close": "2021-03-26",
        "study": "basic",
        "tabTitle": "AAPL",
        "hideInput": false,
        "barchartTicker": "ZCU21",
        "truncate": 3,
        "numberOfContractsApart": null,
        "normalization": null,
        "normalizationMonth": null,
        "normalizationDate": null,
        "forwardCurveStudy": null,
        "constrainContractAligner": null,
        "user": "A4A-user0",
        "buySell": "buy",
        "saveName": "",
        "chartParameters": {
            "lastChartDate": "2021-03-02",
            "balloons": true,
            "skipEmptyPeriods": false,
            "entryType": "limit",
            "hiddenFeatures": {
                "expiration": false,
                "title": false,
                "legends": false,
                "horizontalScrollbar": true,
                "verticalScrollbar": false
            },
            "levels": [
             
            ],
            "firstChartDate": "2020-10-02",
            "start": "2020-11-02",
            "mainSeriesEnd": "2021-11-05"
        },
        "playback": {
            "start": new Date(yesterday).toISOString().split('T')[0],
            "end": new Date(yesterday).toISOString().split('T')[0],
            "interval": 1,
            "breakpoints": []
        },
        "search": {},
        "title": "C2021U",
        "language": "en",
        "showAligned": false,
        "overwriteShow": false,
        "showSeasonals": false,
        "password": "M1-K8xQgmR"
    };
    return obj;
}

export { defaultItem, defaultInitialState, defaultInitialStockState,monthNames }